import { twCustomConfig } from "./tw-config"

const {
  theme: {
    colors: {
      black: { name: defaultPrimaryColor },
      berry: { name: defaultSecondaryColor },
    },
  },
} = twCustomConfig

const colorCombinations = (data = {}) => {
  const themeColor = data.theme_colors_combinations?.value || `berry`

  return {
    primary: "black" || defaultPrimaryColor,
    secondary: themeColor || defaultSecondaryColor,
  }
}

export const getColors = (data) => {
  let themeColor =
    typeof data === "string"
      ? data
      : data?.theme_colors_combinations?.value || defaultSecondaryColor

  return [
    {
      name: "black",
      value: twCustomConfig.theme.colors[`black`],
    },
    {
      name: themeColor,
      value:
        themeColor === "berry"
          ? twCustomConfig.theme.colors["berry"]["500"] // only ever use berry-500 here
          : themeColor === "grey"
          ? twCustomConfig.theme.colors["grey"]["800"]
          : themeColor === "lightgrey"
          ? twCustomConfig.theme.colors["grey"]["600"]
          : null,
    },
  ]
}

export const defaultColors = getColors()

export default colorCombinations
