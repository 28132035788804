/**
 * Shape definitions
 *
 * These are all available UI shapes.
 * They are also available as .svg in the public/shapes folder.
 * The Shape component and shapes in general replace the Letter component.
 * */

const Shape = ({
  type = "circle",
  fill = "black",
  className = "",
  children = {},
}) => {
  let shapeSVG = null

  switch (type) {
    case "circle":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="187"
          height="187"
          viewBox="0 0 187 187"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="93.5" cy="93.5" r="93.5" fill={fill} />
        </svg>
      )
      break
    case "donut":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="347"
          height="347"
          viewBox="0 0 347 347"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M173.207 346.021C268.684 346.021 346.083 268.622 346.083 173.145C346.083 77.6676 268.684 0.268066 173.207 0.268066C77.7296 0.268066 0.330078 77.6676 0.330078 173.145C0.330078 268.622 77.7296 346.021 173.207 346.021ZM173.207 266.232C224.617 266.232 266.294 224.555 266.294 173.145C266.294 121.734 224.617 80.0572 173.207 80.0572C121.796 80.0572 80.1192 121.734 80.1192 173.145C80.1192 224.555 121.796 266.232 173.207 266.232Z"
            fill={fill}
          />
        </svg>
      )
      break
    case "corner-round":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="180"
          height="180"
          viewBox="0 0 180 180"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.91639e-06 0C99.4113 4.3454e-06 180 80.5888 180 180L-7.85678e-06 180L0 0C9.72131e-07 0 1.94426e-06 0 2.91639e-06 0Z"
            fill={fill}
          />
        </svg>
      )
      break
    case "corner-vertical":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="117"
          height="164"
          viewBox="0 0 117 164"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.115676 142.055L0.115662 164H117L117 142.055V0C117 0 57.0967 10.508 26.9017 44.8594C-3.29336 79.2108 0.115676 129.594 0.115676 142.055Z"
            fill={fill}
          />
        </svg>
      )
      break
    case "disphere-d":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="346"
          height="388"
          viewBox="0 0 346 388"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M307.335 18.8301C290.481 37.1647 283.046 62.4368 280.072 79.7803C250.329 56.4904 213.152 42.6156 173 42.6156C77.3295 42.1201 0 119.423 0 215.06C0 310.697 77.3295 388 173 388C268.67 388 344.017 312.184 346 218.529V0C346 0 323.693 1.48659 307.335 18.8301ZM173 307.229C121.943 307.229 80.7994 265.604 80.7994 215.06C80.7994 164.516 122.438 122.891 173 122.891C223.562 122.891 265.201 164.02 265.201 215.06C265.201 266.1 224.057 307.229 173 307.229Z"
            fill={fill}
          />
        </svg>
      )
      break
    case "half-circle":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="130"
          height="260"
          viewBox="0 0 130 260"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M130 260C58.203 260 0 201.797 0 130C0 58.203 58.203 0 130 0V260Z"
            fill={fill}
          />
        </svg>
      )
      break
    case "rect-h-rounded-half":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="505"
          height="117"
          viewBox="0 0 505 117"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M365.267 0.115683L-5.05696e-09 0.115668L-5.11423e-06 117L365.267 117L505 117C505 117 494.664 57.0967 460.874 26.9017C427.084 -3.29334 377.524 0.115684 365.267 0.115683Z"
            fill={fill}
          />
        </svg>
      )
      break
    case "rect-h-rounded":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="527"
          height="117"
          viewBox="0 0 527 117"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M320.5 117L344.561 116.884C345.5 116.884 389.363 116.904 390.716 116.928C407.023 117.209 451.672 117.981 482.874 90.0984C516.664 59.9033 527 0 527 0H233V0.115686L139.733 0.115682C138.794 0.115682 137.637 0.0956841 136.284 0.072318C119.977 -0.209393 75.3282 -0.980698 44.1262 26.9017C10.3363 57.0967 0 117 0 117L294 117H320.5Z"
            fill={fill}
          />
        </svg>
      )
      break
    case "rect-vertical":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="117"
          height="505"
          viewBox="0 0 117 505"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.11569 139.733V505H117V139.733V0C117 0 57.0967 10.3363 26.9017 44.1262C-3.29334 77.9161 0.11569 127.476 0.11569 139.733Z"
            fill={fill}
          />
        </svg>
      )
      break
    case "rect-vertical-wide":
      shapeSVG = (
        <svg
          className={className}
          fill={fill}
          width="333"
          height="570"
          viewBox="0 0 333 570"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.329231 397.551L0.329191 570H333L333 397.551V0C333 0 162.506 29.4074 76.5662 125.542C-9.3734 221.677 0.329231 362.678 0.329231 397.551Z"
            fill="#F50F55"
          />
        </svg>
      )
      break
    default:
      shapeSVG = null
  }

  return Object.keys(children).length > 0 ? (
    <div>
      {shapeSVG}
      {children}
    </div>
  ) : (
    shapeSVG
  )
}

export default Shape
